import React, {FunctionComponent, useContext, useState} from "react"
import routes from "../routes";
import { Redirect } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import { getMessage } from "../utils/Tools"
import ClientStorage from "../utils/ClientStorage"

const InvalidCredentials: FunctionComponent = () => {

    const { onLogout, whiteLabelConfig } = useContext(AuthContext)
    const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false)

    const Message = getMessage(whiteLabelConfig)

    if (redirectToLogin) {
        onLogout()
        return <Redirect to={ClientStorage.getItem('IsSsoLoginEnabled') ? routes.LOGIN_WITH_SSO : routes.LOGIN} />
    }

    return (
        <div className="shiftNotFound contentBox">
            <p className="marginBottom14">
                {Message("contactSupervisorReset")}
            </p>

            <div className="bottomBar">
                <div className="contentBox textAlignCenter">
                    <button onClick={() => {
                        setRedirectToLogin(true)
                    }}>{Message("goToLogin")}</button>
                </div>
            </div>
        </div>
    )
}

export default InvalidCredentials
