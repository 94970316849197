import React, { useEffect, useState, FunctionComponent } from "react"
import moment, { Moment } from "moment"

interface Props {
    format?: string
}

const defaultFormat: string = "HH:mm:ss"

const Clock: FunctionComponent<Props> = ({ format }) => {
    const [clock, setClock] = useState<Moment>(moment())

    useEffect(() => {
        const clock = setInterval(() => {
            setClock(moment())
        }, 1000)
        return () => {
            clearInterval(clock)
        }
    }, [])

    return <span className="clock">{clock.format(format || defaultFormat)}</span>
}

export default Clock
