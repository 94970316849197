import React, { FunctionComponent, useState, useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { getMessage, useInterval } from "../utils/Tools"

interface Props {
    onCountdownComplete: () => void
    initialCountdownSeconds: number
    displayedMessage: string
}

const CountdownPageRedirect: FunctionComponent<Props> = ({ onCountdownComplete, initialCountdownSeconds, displayedMessage }: Props) => {
    const { whiteLabelConfig } = useContext(AuthContext)
    const [countdown, setCountdown] = useState<number>(Math.abs(Math.round(initialCountdownSeconds)))

    const Message = getMessage(whiteLabelConfig)

    useInterval(() => {
        if (countdown === 0) {
            onCountdownComplete()
        } else {
            setCountdown(countdown - 1)
        }
    }, 1000)

    const appearCountdown = countdown != 0;

    return (

        <div className="countdownRedirectBox">{Message(displayedMessage)} {appearCountdown && (countdown)}</div>
    )
}

export default CountdownPageRedirect
