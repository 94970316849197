import React, { Fragment, FunctionComponent, useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { getMessage } from "../utils/Tools"

const Footer: FunctionComponent = () => {
    const { whiteLabelConfig } = useContext(AuthContext)

    const Message = getMessage(whiteLabelConfig)

    return (
        <Fragment>
            <div className="footer">
                <div className="footerLeft">
                    <div className="inner">
                    </div>
                </div>
                <div className="footerRight">
                    <div className="inner">
                        <div className="poweredBy">
                            <span>Powered by </span>
                            <a target="_blank" href="https://www.joinedup.com/" rel="noopener noreferrer"><img src={require("../assets/poweredby.png")}  alt={Message("joinedUp")}/></a>
                        </div>
                        &copy; {Message("copyRight")}
                    </div>
                    <div className="version">v.{process.env.REACT_APP_VERSION}</div>
                </div>
            </div>
        </Fragment>
    )
}

export default Footer