import React, { useContext, useEffect } from "react"
import { InteractionMode } from "interfaces/InteractionMode"
import { AuthContext } from "providers/AuthProvider"
import CountdownPageRedirect from "./CountdownPageRedirect"
import { getMessage } from "../utils/Tools"

export default function NotEnabled({ onRestart, interactionMode, idleRestartSeconds }: {
    onRestart: () => void,
    interactionMode?: InteractionMode,
    idleRestartSeconds: number
}) {
    const { operationalModeConfig, whiteLabelConfig } = useContext(AuthContext)
    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        setTimeout(onRestart, 1000 * idleRestartSeconds)
    }, [])

    return (
        <div className="contentBox textAlignCenter">
            <p className="marginBottom14"> {Message("tabletsNotEnabled")}</p>
            <p> {Message("contactSupport")} </p>

            <div className="bottomBar">
                <div className="contentBox textAlignCenter">
                    {interactionMode === InteractionMode.CONTACTLESS
                        ? <CountdownPageRedirect initialCountdownSeconds={resetCountdown} displayedMessage="stepAside" onCountdownComplete={onRestart} />
                        : (
                            <button className="grey" onClick={onRestart}>
                                {Message("back")}
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}