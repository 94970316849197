import React, { useState, FunctionComponent } from "react"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import Auth from "../auth/Auth"

interface Value {
    connectedUser: ConnectedUser | null
    operationalModeConfig: OperationalModeConfig | null
    whiteLabelConfig: WhiteLabelConfig | null
    onLogin: (user: ConnectedUser) => void
    onLogout: () => void
    onSetOperationalModeConfig: (operationalModeConfig: OperationalModeConfig) => void
    onSetWhiteLabelConfig: (whiteLabelConfig: WhiteLabelConfig) => void
}

const defaultValue: Value = {
    connectedUser: Auth.getConnectedUser(),
    operationalModeConfig: Auth.getOperationalModeConfig(),
    whiteLabelConfig: Auth.getWhiteLabelConfig(),
    onLogin: () => {},
    onLogout: () => {},
    onSetOperationalModeConfig: () => {},
    onSetWhiteLabelConfig: () => {}
}

export const AuthContext = React.createContext(defaultValue)

export interface AuthProviderProps {
    initialConnectedUser?: ConnectedUser | null
    initialOperationalModeConfig?: OperationalModeConfig | null
    initialWhiteLabelConfig?: WhiteLabelConfig | null
}

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
    children,
    initialConnectedUser,
    initialOperationalModeConfig,
    initialWhiteLabelConfig
}) => {
    const [connectedUser, setConnectedUser] = useState<ConnectedUser | null>(
        defaultValue.connectedUser || initialConnectedUser || null
    )
    const [operationalModeConfig, setOperationalModeConfig] = useState<OperationalModeConfig | null>(
        defaultValue.operationalModeConfig || initialOperationalModeConfig || null
    )
    const [whiteLabelConfig, setWhiteLabelConfig] = useState<WhiteLabelConfig | null>(
        defaultValue.whiteLabelConfig || initialWhiteLabelConfig || null
    )

    const onLogin = (user: ConnectedUser) => {
        setConnectedUser(user)
        Auth.login(user)
    }

    const onLogout = () => {
        setConnectedUser(null)
        Auth.logout()
    }

    const onSetOperationalModeConfig = (operationalModeConfig: OperationalModeConfig) => {
        setOperationalModeConfig(operationalModeConfig)
        Auth.setOperationalModeConfig(operationalModeConfig)
    }

    const onSetWhiteLabelConfig = (whiteLabelConfig: WhiteLabelConfig) => {
        setWhiteLabelConfig(whiteLabelConfig)
        Auth.setWhiteLabelConfig(whiteLabelConfig)
    }

    return (
        <AuthContext.Provider
            value={{
                onLogin,
                onLogout,
                connectedUser,
                operationalModeConfig,
                onSetOperationalModeConfig,
                whiteLabelConfig,
                onSetWhiteLabelConfig
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
