const endpoints = {
    auth: {
        LOGIN: "/login"
    },
    sso: {
        IDENTITY_PROVIDERS: "/identity_providers"
    },
    agent: {
        GET_SITES: "/findSites",
        SELECT_SITE: "/selectSite"
    },
    worker: {
        FIND_SHIFT_BY_FACE: "/findShift",
        START_SHIFT: "/startShift",
        STOP_SHIFT: "/stopShift",
        GET_AVATAR: "/getImage",
        START_BREAK: "/startBreak",
        STOP_BREAK: "/stopBreak",
    },
    featureSwitches: {
        GET_FOR_ORG: "/featureSwitches",
        GET_ENABLE_SSO_LOGIN_PAGE: "/enableSSOLoginPage"
    }
}

export default endpoints
