import Auth from "../auth/Auth"

const apiUrl: string | undefined = process.env.REACT_APP_API_URL

export enum RequestType {
    GET = "GET",
    POST = "POST"
}

export interface APIParameters {
    endpoint: string
    requestType?: RequestType
    params?: any
    withApiKey?: boolean
    withToken?: boolean
}

export const executeRequest = async ({
    endpoint,
    requestType,
    params,
    withApiKey,
    withToken
}: APIParameters): Promise<any> => {
    const headers: Headers = new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8"
    })
    if (withApiKey) {
        const apiKey: string | undefined = Auth.getOperationalModeConfig()?.apiKey
        if (apiKey) {
            headers.append("Authorization", "ApiKey " + btoa(apiKey))
        }
    }
    if (withToken) {
        const token: string | undefined = Auth.getConnectedUser()?.token
        if (token) {
            headers.append("Authorization", "Bearer " + btoa(token))
        }
    }

    let request
    if (requestType == RequestType.POST) {
        request = {
            method: requestType,
            body: JSON.stringify(params),
            headers
        }
    } else {
        request = {
            method: requestType,
            headers
        }
    }

    const response = await fetch(`${apiUrl}${endpoint}`, request)
    const responseJson = await response.json()
    if (!response.ok) {
        throw new Error(responseJson.errors ? responseJson.errors.join() : response.statusText)
    }

    return responseJson
}
