import React, {Fragment, FunctionComponent, useContext, useEffect, useState} from "react"
import moment from "moment"
import LoaderButton from "./LoaderButton"
import {executeRequest, RequestType} from "../api/APIUtils"
import endpoints from "../api/endpoints"
import {Shift} from "../interfaces/Shift"
import {Worker} from "../interfaces/Worker"
import Clock from "./Clock"
import {AppContext, FeedbackType} from "../providers/AppProvider"
import {AuthContext} from "../providers/AuthProvider"
import Modal from "./Modal"
import { InteractionMode } from "../interfaces/InteractionMode"
import CountdownPageRedirect from "./CountdownPageRedirect"
import { getMessage } from "../utils/Tools"

const defaultKeySuffix: string = "_cropped"
const fallbackKeySuffix: string = "_290"
const STANDARD_DATE_FORMAT = "DD-MM-YYYY HH:mm"

const formattedNow = () => {
    const now = moment()
    return {
        day: now.format("DD-MM-YYYY"),
        time: now.format("HH:mm")
    }
}

const formattedDateTimeString = (dateTimeString: string): string => {
    const dateTime = moment(dateTimeString)
    return `${dateTime.format("HH:mm")} (on ${dateTime.format("ddd")})`
}

interface ShiftRequest {
    id: number
    workerId: number
    day: string
    time: string
    reason?: string
}

interface ShiftResponse {
    success: boolean
    shift: Shift
}

interface AvatarResponse {
    success: boolean
    imageBytes: string
}

interface AvatarData {
    image?: string
    requestComplete: boolean
}

interface Props {
    shift: Shift
    worker: Worker
    onRestart: () => void
    interactionMode: InteractionMode | undefined
    idleRestartSeconds: number
}

interface ActionLabel {
    default: string
    loading: string
}

interface ActionResult {
    handler: () => void
    label: ActionLabel
}

const ShiftFound: FunctionComponent<Props> = ({ shift, worker, onRestart, interactionMode, idleRestartSeconds }) => {
    const { setFeedback, getFeaturesSwitches } = useContext(AppContext)
    const { operationalModeConfig, whiteLabelConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [secondActionLoading, setSecondActionLoading] = useState<boolean>(false)
    const [isSecondAction, setIsSecondAction] = useState<boolean>(false)
    const [shiftInState, setShiftInState] = useState<Shift>(shift)
    const [isDone, setIsDone] = useState<boolean>(false)
    const [isDoneWithError, setIsDoneWithError] = useState<boolean>(false)
    const [avatarData, setAvatarData] = useState<AvatarData | null>(null)
    const [reason, setReason] = useState<string | undefined>(undefined)
    const [showReasonModal, setShowReasonModal] = useState<boolean>(false)
    const [featureSwitches, setFeatureSwitches] = useState<string[]>([])
    const [nextActionState, setNextActionState] = useState<ActionResult>({ handler: () => {}, label: { default: "", loading: "" }})
    const [hasValidAction, setHasValidAction] = useState<boolean>(false)
    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        const fetchFeatureSwitches = async () => {
        if (!operationalModeConfig) { return }
            setFeatureSwitches(await getFeaturesSwitches(operationalModeConfig!.orgId))
        }
        fetchFeatureSwitches()
    }, [])

    useEffect(() => {
        if (interactionMode === InteractionMode.CONTACTLESS)
        {
            setTimeout(onRestart, 2000 * idleRestartSeconds)
        }
    }, [])

    useEffect(() => {
        setLoading(false)

        const nextAction = async () => {
            setNextActionState(await getNextAvailableAction(shift.action))
        }
        nextAction()
    }, [shift])

    useEffect(() => {
        if (!worker.avatarPath) {
            setAvatarData({
                requestComplete: true
            })
            return
        }

        const getAvatar = async () => {
            try {
                let avatarResponse: AvatarResponse = await executeGetAvatarRequest(defaultKeySuffix)
                if (!avatarResponse || !avatarResponse.success) {
                    avatarResponse = await executeGetAvatarRequest(fallbackKeySuffix)
                }

                if (avatarResponse && avatarResponse.success) {
                    setAvatarData({
                        image: "data:image/png;base64," + avatarResponse.imageBytes,
                        requestComplete: true
                    })
                } else {
                    throw new Error()
                }
            } catch (_) {
                setAvatarData({ requestComplete: true })
            }
        }
        getAvatar()
    }, [worker])

    const executeGetAvatarRequest = (keySuffix: string): Promise<AvatarResponse> => {
        try {
            return executeRequest({
                endpoint: `${endpoints.worker.GET_AVATAR}?key=${worker.avatarPath + keySuffix}`,
                withApiKey: true,
                requestType: RequestType.GET
            })
        } catch (error) {
            console.error(error)
            return new Promise<AvatarResponse>((resolve => { resolve({ success: false, imageBytes: "" }) }))
        }
    }

    const earlyStopReasonEnabled = () => {
        return (featureSwitches || []).includes("ENABLE_EARLY_STOP_SHIFT_REASON")
    }

    const startIntroContent = (worker: Worker) => {
        const partOfDay = moment().hours() >= 12 ? Message("afternoon") : Message("morning")
        return (
            <Fragment>
                <p className="marginBottom14">
                    {Message("welcomeWorker", [partOfDay, worker.firstName])}
                </p>
                <p>{Message("shiftFound")}</p>
                <p>{Message("shiftCheckStart")}</p>
            </Fragment>
        )
    }

    const justStartedContent = (worker: Worker) => (
        <Fragment>
            <p className="marginBottom14">{Message("shiftStarted", [worker.firstName])}</p>
            <p>{Message("rememberStopShift")}</p>
        </Fragment>
    )

    const stopIntroContent = (worker: Worker, actStartTime: string) => (
        <Fragment>
            <p className="marginBottom14">{Message("helloAgain", [worker.firstName])}</p>
            <p>{Message("shiftWorkingSince", [actStartTime])}</p>
            <p>{Message("shiftStopNow")}</p>
        </Fragment>
    )

    const justStoppedContent = (worker: Worker) => <p>{Message("shiftStopCongrats", [worker.firstName])}</p>

    const outputContentBlock = (shift: Shift, worker: Worker, isDone: boolean) => {
        if (shift.actStopTime) {
            return justStoppedContent(worker)
        }
        if (shift.actStartTime) {
            if (isDone) {
                return justStartedContent(worker)
            }
            return stopIntroContent(worker, moment(shift.actStartTime).format("HH:mm"))
        }
        return startIntroContent(worker)
    }

    const startShift = async () => {
        setHasValidAction(false)

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)

        const startShiftRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...formattedNow()
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.START_SHIFT,
                withApiKey: true,
                requestType: RequestType.POST,
                params: startShiftRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    // server sends us stringified datetime in "DD-MM-YYYY HH:mm" format
                    actStartTime: moment(shift.actStartTime, STANDARD_DATE_FORMAT).toString()
                })
                setIsDone(true)
                setFeedback({
                    type: FeedbackType.SUCCESS,
                    message: Message("shiftStartSubmittedSuccessfully")
                })
            } else {
                throw new Error()
            }
        } catch (error) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }

    const startBreak = async () => {
        setHasValidAction(false)
        setShiftInState({ ...shiftInState, canStopShift: false })

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)

        const startBreakRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...formattedNow()
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.START_BREAK,
                withApiKey: true,
                requestType: RequestType.POST,
                params: startBreakRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    // server sends us stringified datetime in "DD-MM-YYYY HH:mm" format
                    breakStartTime: moment(shift.breakStartTime, STANDARD_DATE_FORMAT).toString(), canStopShift: false
                })
                setIsDone(true)
                setFeedback({
                    type: FeedbackType.SUCCESS,
                    message: Message("shiftBreakStartSubmittedSuccessfully")
                })
            } else {
                throw new Error()
            }
        } catch (error) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }

    }

    const stopBreak = async () => {
        setHasValidAction(false)

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        setLoading(true)

        const stopBreakRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...formattedNow()
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.STOP_BREAK,
                withApiKey: true,
                requestType: RequestType.POST,
                params: stopBreakRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    // server sends us stringified datetime in "DD-MM-YYYY HH:mm" format
                    breakStopTime: moment(shift.breakStopTime, STANDARD_DATE_FORMAT).toString()
                })
                setIsDone(true)
                setFeedback({
                    type: FeedbackType.SUCCESS,
                    message: Message("shiftBreakStopSubmittedSuccessfully")
                })
            } else {
                throw new Error()
            }
        } catch (error) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }

    const stopShift = async () => {
        if (shiftInState.action === "START_BREAK")
        {
            setIsSecondAction(true)
            setNextActionState(await getNextAvailableAction("STOP_SHIFT"))
        }
        setHasValidAction(false)

        if (!operationalModeConfig) {
            console.error(Message("noOperationalModeConfig"))
            return
        }

        if (moment(shift.expectedStopTime).isAfter(moment()) && !!!reason && earlyStopReasonEnabled()) {
            setShowReasonModal(true)
            return
        }

        setLoading(true)
        setSecondActionLoading(true)

        const stopShiftRequest: ShiftRequest = {
            id: shift.id,
            workerId: worker.id,
            ...formattedNow(),
            reason
        }

        try {
            const { success, shift }: ShiftResponse = await executeRequest({
                endpoint: endpoints.worker.STOP_SHIFT,
                withApiKey: true,
                requestType: RequestType.POST,
                params: stopShiftRequest
            })
            if (success) {
                setShiftInState({
                    ...shiftInState,
                    // server sends us stringified datetime in "DD-MM-YYYY HH:mm" format
                    actStopTime: moment(shift.actStopTime, STANDARD_DATE_FORMAT).toString(), action: "STOP_SHIFT"
                })
                setIsDone(true)
                setShowReasonModal(false)
                setFeedback({
                    type: FeedbackType.SUCCESS,
                    message: Message("shiftStopSubmittedSuccessfully")
                })
            } else {
                throw new Error()
            }
        } catch (error) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
        } finally {
            setLoading(false)
            setSecondActionLoading(false)
        }
    }

    const outputAvatar = () => {
        if (!avatarData) {
            return <div className="loader" />
        }
        if (avatarData.image) {
            return <img src={avatarData.image} alt="" />
        }
    }

    const actionLabels: { [key: string]: ActionLabel } = {
        START_SHIFT: {
            default: Message("startShift"),
            loading: Message("startingShift")
        },
        STOP_SHIFT: {
            default: Message("stopShift"),
            loading: Message("stoppingShift")
        },
        START_BREAK: {
            default: Message("startBreak"),
            loading: Message("startingBreak")
        },
        STOP_BREAK: {
            default: Message("stopBreak"),
            loading: Message("stoppingBreak")
        },
        NO_ACTION: {
            default: "",
            loading: Message("loadingAction")
        }
    }

    const getLabelKey = (loading : boolean) : keyof ActionLabel => {
        return loading
            ? "loading"
            : "default"
    }

    const getNextAvailableAction = (action: string) : ActionResult => {
        if (interactionMode !== InteractionMode.TOUCH && (action == 'START_BREAK' || action == 'STOP_BREAK')) {
            setIsDoneWithError(true)
            setFeedback({
                type: FeedbackType.ERROR,
                message: Message("contactlessWithBreaks")
            })
            return { handler: () => {}, label: actionLabels.NO_ACTION }
        }


        setHasValidAction(true)

        if (action == 'START_SHIFT') {
            return { handler: startShift, label: actionLabels.START_SHIFT }
        } else if (action == 'START_BREAK') {
            return { handler: startBreak, label: actionLabels.START_BREAK }
        } else if (action == 'STOP_BREAK') {
            return { handler: stopBreak, label: actionLabels.STOP_BREAK }
        } else if  (action == 'STOP_SHIFT') {
            return { handler: stopShift, label: actionLabels.STOP_SHIFT }
        }
        return { handler: () => {}, label: actionLabels.NO_ACTION }
    }

    return (
        <div className="shiftFound contentBox">
            {showReasonModal && earlyStopReasonEnabled() && (
                <Modal onRequestClose={() => {
                    setShowReasonModal(false)
                }}>
                    {Message("shiftEarlierEnding")}

                    <textarea
                        onChange={e => {
                            setReason(e.target.value)
                        }}
                    />

                    <div className="textAlignCenter">
                        <LoaderButton
                            disabled={!!!reason}
                            onClick={stopShift}
                            loading={loading}
                            extraClass="wider"
                        >
                            {actionLabels.STOP_SHIFT[getLabelKey(loading)]}
                        </LoaderButton>
                    </div>
                </Modal>
            )}

            {interactionMode !== InteractionMode.QR && <div className="field textAlignCenter">
                <div className="workerPhoto">{outputAvatar()}</div>
            </div>}

            {outputContentBlock(shiftInState, worker, isDone)}

            <div className="shiftBlock first">
                <div className="time">
                    <div>{moment(shiftInState.bookedStartTime).format("ddd D MMM")}</div>
                    <div className="muted">{Message("shiftStartTime")} {moment(shiftInState.bookedStartTime).format("HH:mm")}</div>
                </div>
                <div className="shift">
                    <p><b>{shiftInState.shiftTemplateAlias}</b> {Message("atSiteFor", [shiftInState.siteName])}</p>
                    <p>{shiftInState.organisationName}</p>
                </div>
            </div>
            {shiftInState.actStartTime && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftActualStart")}</div>
                    <div className="shift">{formattedDateTimeString(shiftInState.actStartTime)}</div>
                </div>
            )}
            {shiftInState.breakStartTime && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftBreakStart")}</div>
                    <div className="shift">{formattedDateTimeString(shiftInState.breakStartTime)}</div>
                </div>
            )}
            {shiftInState.breakStopTime && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftBreakStop")}</div>
                    <div className="shift">{formattedDateTimeString(shiftInState.breakStopTime)}</div>
                </div>
            )}
            {shiftInState.actStopTime && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftStopTime")}</div>
                    <div className="shift">{formattedDateTimeString(shiftInState.actStopTime)}</div>
                </div>
            )}

            {!isDone && !shift.actStopTime && (
                <div className="startOrStopShift">
                    <div className="time">
                        <div>{Message("timeNow")}</div>
                        <div className="larger">
                            <Clock />
                        </div>
                    </div>
                    <div className="action">
                        {interactionMode !== InteractionMode.TOUCH && hasValidAction
                            ? <CountdownPageRedirect initialCountdownSeconds={0} displayedMessage="stepAside" onCountdownComplete={nextActionState.handler} />
                            : <div>
                                {interactionMode !== InteractionMode.TOUCH && !isDoneWithError && (
                                    <LoaderButton
                                        onClick={nextActionState.handler}
                                        loading={loading}
                                        extraClass="wider"
                                    >
                                        {nextActionState.label[getLabelKey(loading)]}
                                    </LoaderButton>
                                )}
                            </div>
                        }

                        {interactionMode === InteractionMode.TOUCH && !isSecondAction &&(
                            <LoaderButton
                                onClick={nextActionState.handler}
                                loading={loading}
                                extraClass="wider"
                            >
                                {nextActionState.label[getLabelKey(loading)]}
                            </LoaderButton>
                        )}
                    </div>
                </div>
            )}

            {shiftInState.action === "START_BREAK" && shiftInState.canStopShift && interactionMode === InteractionMode.TOUCH &&(
                <div className="secondaryAction">
                    <LoaderButton
                        onClick={stopShift}
                        loading={secondActionLoading}
                        extraClass={"fullWidth"}>
                        {actionLabels.STOP_SHIFT[getLabelKey(secondActionLoading)]}
                    </LoaderButton>
                </div>
            )}

            {interactionMode !== InteractionMode.TOUCH && (isDone || isDoneWithError) && (
                <div className={`bottomBar ${isDone ? "textAlignCenter" : ""}`}>
                    <div className="contentBox">
                        {isDone && (
                            <CountdownPageRedirect initialCountdownSeconds={resetCountdown} displayedMessage="stepAside" onCountdownComplete={onRestart} />
                        )}
                        {isDoneWithError && (
                            <CountdownPageRedirect initialCountdownSeconds={resetCountdown} displayedMessage="tryAgain" onCountdownComplete={onRestart} />
                        )}
                    </div>
                </div>
            )}

            {interactionMode === InteractionMode.TOUCH && (
                <div className={`bottomBar ${isDone ? "textAlignCenter" : ""}`}>
                    <div className="contentBox">
                        {isDone
                            ? (
                                <button onClick={onRestart}>{Message("done")}</button>
                            ) : (
                                <button className="grey" onClick={onRestart}>
                                    {Message("back")}
                                </button>
                            )
                        }
                    </div>
                </div>
            )}
        </div>
    )
}
export default ShiftFound
