import React, { Fragment, FunctionComponent } from "react"

interface Props {
    onClick: () => void
    disabled?: boolean
    loading: boolean
    children: React.ReactNode
    extraClass?: string
}

const LoaderButton: FunctionComponent<Props> = ({ onClick, disabled, loading, children, extraClass }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`loaderButton ${loading ? "loading" : ""} ${extraClass ? extraClass : ""}`}
        >
            <Fragment>
                {loading && <div className="loader"></div>}
                {children}
            </Fragment>
        </button>
    )
}

export default LoaderButton
