import React, { FunctionComponent, useContext, useEffect } from "react"
import { InteractionMode } from "../interfaces/InteractionMode"
import CountdownPageRedirect from "./CountdownPageRedirect"
import { getMessage } from "../utils/Tools"

import { AuthContext } from "../providers/AuthProvider"

interface Props {
    onRestart: () => void
    interactionMode: InteractionMode | undefined
    idleRestartSeconds: number
}

const FaceNotFound: FunctionComponent<Props> = ({ onRestart, interactionMode, idleRestartSeconds }: Props) => {
    const { operationalModeConfig, whiteLabelConfig } = useContext(AuthContext)
    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        setTimeout(onRestart, 1000 * idleRestartSeconds)
    }, [])

    return (
        <div className="shiftNotFound contentBox">
            <p className="marginBottom14">{Message("notRecognise")}</p>
            <p>{Message("contactSupervisor")}</p>

            <div className="bottomBar">
                <div className="contentBox textAlignCenter">
                    {interactionMode !== InteractionMode.TOUCH
                        ? <CountdownPageRedirect initialCountdownSeconds={resetCountdown} displayedMessage="stepAside" onCountdownComplete={onRestart} />
                        : <button onClick={onRestart}>{Message("startAgain")}</button>}
                </div>
            </div>
        </div>
    )
}

export default FaceNotFound
