import React, { Dispatch, FunctionComponent, SetStateAction, useState } from "react"
import { message } from "../messages"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import NodeCache from "node-cache"

const cache = new NodeCache({
    stdTTL: process.env.NODE_ENV === "development" ? 1 : 600,
    checkperiod: 0,
    useClones: false
})

export enum FeedbackType {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export interface Feedback {
    type: FeedbackType
    message?: string
    showLoginPromptOnInvalidCredentials?: boolean
}

interface DefaultValueProps {
    menuOpen: boolean
    feedback: Feedback | undefined
    getFeaturesSwitches: (orgId: number) => Promise<string[]>
    setFeedback: (feedback: Feedback | undefined) => void
    setMenuOpen: Dispatch<SetStateAction<boolean>>
    toggleMenu: () => void
    onNavigate: () => void
}

const defaultValue: DefaultValueProps = {
    menuOpen: false,
    feedback: undefined,
    getFeaturesSwitches: () => Promise.resolve([]),
    setFeedback: () => {},
    setMenuOpen: () => {},
    toggleMenu: () => {},
    onNavigate: () => {}
}

export const AppContext = React.createContext(defaultValue)

export const AppProvider: FunctionComponent = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(defaultValue.menuOpen)
    const [feedback, setFeedback] = useState<Feedback | undefined>(defaultValue.feedback)

    const setFeedbackWithFormattedMessage = (feedback: Feedback | undefined) => {
        if (feedback) {
            const showLoginPrompt =
                feedback.showLoginPromptOnInvalidCredentials && feedback.message === "INVALID_CREDENTIALS"
            setFeedback({
                type: feedback.type,
                message: message(feedback.message),
                showLoginPromptOnInvalidCredentials: showLoginPrompt
            })
            return
        }
        setFeedback(undefined)
    }

    const getFeaturesSwitches = async (orgId: number): Promise<string[]> => {
        if (!cache.has("switches")) {
            const response = await executeRequest({
                endpoint: `${endpoints.featureSwitches.GET_FOR_ORG}?orgId=${orgId}`,
                withApiKey: true,
                requestType: RequestType.GET
            })
            cache.set("switches", response.featureSwitches)
        }
        return Promise.resolve(cache.get<string[]>("switches") ?? [])
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const onNavigate = () => {
        setFeedback(undefined)
        setMenuOpen(false)
    }

    return (
        <AppContext.Provider
            value={{
                menuOpen,
                setMenuOpen,
                feedback,
                getFeaturesSwitches,
                setFeedback: setFeedbackWithFormattedMessage,
                toggleMenu,
                onNavigate
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
