import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { getMessage } from "../utils/Tools"
import { AuthContext } from "../providers/AuthProvider"
import { FormData, LoginRequest } from "./Login"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import { RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import routes from "../routes"
import { useHistory } from "react-router"
import Cookies from "../providers/CookieProvider"
import LoaderButton from "./LoaderButton"

interface IdentityProvider {
    identityProviderName: string
    identityProviderUrl: string
    encodedIdentityRequest: string
    host: string
}

const DEFAULT_FORM_STATE = { email: "", password: "" }

const LoginPage = () => {
    const { onLogin, onLogout, onSetWhiteLabelConfig, whiteLabelConfig } = useContext(AuthContext)
    const { setFeedback, feedback } = useContext(AppContext)
    const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_STATE)
    const history = useHistory()

    const [ssoToken, setSSOToken] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [trySSOLogin, setTrySSOLogin] = useState<boolean>(true)
    const [validSSOAuth, setValidSSOAuth] = useState<boolean>(false)
    const [identityProviders, setIdentityProviders] = useState<IdentityProvider[]>([])

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        if (Cookies.get("azureToken") !== undefined) {
            setSSOToken(Cookies.get("azureToken"))
            setFormData({ ...formData, email: Cookies.get("email") })
            Cookies.removeSSO("azureToken")
            Cookies.removeSSO("email")
            Cookies.removeSSO("ssoDomain")
        }
        if (Cookies.get("azureError") !== undefined) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: Cookies.get("azureError").replaceAll("_", " ")
            })
            setFormData(DEFAULT_FORM_STATE)
            setLoading(false)
            Cookies.removeSSO("azureError")
            Cookies.removeSSO("ssoDomain")
        }

        onLogout()
    }, [])

    useEffect(() => {
        if (ssoToken !== "") {
            login()
        }
    }, [ssoToken])

    useEffect(() => {
        login()
        setTrySSOLogin(false)
    }, [])


    const login = async () => {
        setLoading(true)

        const loginRequest: LoginRequest = {
            email: formData.email,
            password: formData.password,
            trySSOLogin: trySSOLogin,
            ssoToken: ssoToken,
            ssoCallback: window.location.origin + '/loginWithSso',
            loginSSO: true
        }

        try {

            const headers: Headers = new Headers({
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json; charset=utf-8"
            })

            let request = {
                method: RequestType.POST,
                body: JSON.stringify(loginRequest),
                headers
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.auth.LOGIN}`, request)
            const result = await response.json()

            setLoading(false)

            if (result.success) {
                if (result.samlRequest) {
                    setValidSSOAuth(true)
                    setIdentityProviders([result.samlRequest])
                } else {
                    const user: ConnectedUser = {
                        id: result.user.id,
                        email: result.user.email,
                        tempOrgId: result.user.orgId,
                        tempWhitelabelContext: result.user.tempWhitelabelContext,
                        token: result.user.token
                    }
                    onLogin(user)
                    if (whiteLabelConfig?.context !== user.tempWhitelabelContext) {
                        const tempWhiteLabelConfig: WhiteLabelConfig = {
                            context: user.tempWhitelabelContext,
                            lang: whiteLabelConfig!.lang
                        }
                        onSetWhiteLabelConfig(tempWhiteLabelConfig)
                    }
                    history.push(routes.SELECT_SITE)
                }
            } else {
                if (result.errors.key === "NO_SSO_AUTH") {
                    setTrySSOLogin(false)
                } else {
                    setFeedback({
                        type: FeedbackType.ERROR,
                        message: result.errors.message
                    })
                }
            }
        } catch (error) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
            setFormData(DEFAULT_FORM_STATE)
            setLoading(false)
        }
    }

    const goToForgotPassword = () => {
        window.open(process.env.REACT_APP_FORGOT_PASSWORD_URL, "_blank")
    }

    const outputIdentityProviders = () => {
        return (
            identityProviders.length > 0 &&
            identityProviders.map((identityProvider: any) => {
                const ssoLogoPath = identityProvider.identityProviderName.toLowerCase() === "staffline" ?
                    "ssoHosts/staffline.png" : `ssoHosts/${identityProvider.host.toLowerCase()}.svg`
                return (
                    <React.Fragment key={identityProvider.identityProviderName}>
                        <form
                            action={identityProvider.identityProviderUrl}
                            method="POST"
                            className="form-vertical login loginWithSso"
                        >
                            <input type="hidden" name="SAMLRequest" value={identityProvider.encodedIdentityRequest} />
                            <button
                                key={identityProvider.identityProviderName}
                                type="submit"
                            >
                                <img
                                    src={ssoLogoPath}
                                    alt={`${identityProvider.host} logo`}
                                    className={identityProvider.identityProviderName}
                                />
                                {Message(`loginSsoContinueWith`)}{" "}
                                {identityProvider.identityProviderName}
                            </button>
                        </form>
                    </React.Fragment>
                )
            })
        )
    }

    const onFieldFocus = () => {
        if (feedback) {
            setFeedback(undefined)
        }
    }

    const disabledStatus = (): boolean => {
        return formData.email === "" || formData.password === ""
    }

    return (
        <div className="loginWrapper">
            <div>
                <p className="welcomeMessage" dangerouslySetInnerHTML={{ __html: Message("loginWelcome") }} />
            </div>
            <fieldset className="fieldset">
                <div className="innerFieldset">
                    <h1>{Message("loginTitle")}</h1>
                    <div className="alertsWrapper">
                        <div className="alert alert-info">{Message("loginSso")}</div>
                    </div>
                    <div className="buttonsWrapper">
                        {outputIdentityProviders()}
                    </div>

                    <div className="loginForm">
                        <div className="field">
                            <label htmlFor="email">{Message("email")}</label>
                            <input
                                type="email"
                                name="email"
                                aria-label="email"
                                autoComplete="disabled"
                                value={formData.email}
                                placeholder={Message("emailPlaceHolder")}
                                onFocus={onFieldFocus}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFormData({ ...formData, email: e.currentTarget.value })
                                }
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="password">{Message("password")}</label>
                            <input
                                type="password"
                                name="password"
                                aria-label="password"
                                value={formData.password}
                                placeholder={Message("passwordPlaceHolder")}
                                onFocus={onFieldFocus}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFormData({ ...formData, password: e.currentTarget.value })
                                }
                            />
                        </div>
                        <div className="loginButtonWrapper">
                            <div onClick={goToForgotPassword}>{Message("loginForgottenPassword")}</div>
                            <LoaderButton onClick={login} loading={loading}
                                          disabled={disabledStatus()}>{Message("login")}</LoaderButton>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    )
}

export default LoginPage
