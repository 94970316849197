import React, { FunctionComponent, useEffect, useState, useContext } from "react"
import * as serviceWorker from "../serviceWorker"
import "../assets/app.css"
import { BrowserRouter } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import { defaultWhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import FeedbackBar from "./FeedbackBar"
import Main from "./Main"
import { getMessage } from "../utils/Tools"

const SystemMessage: FunctionComponent = ({ children }) => <div className="systemMessage">{children}</div>

const App: FunctionComponent = () => {
    const { whiteLabelConfig, onSetWhiteLabelConfig } = useContext(AuthContext)
    const [initialised, setInitialised] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null)

    useEffect(() => {
        const whiteLabelConfigToUse = !!whiteLabelConfig ? whiteLabelConfig : defaultWhiteLabelConfig
        onSetWhiteLabelConfig(whiteLabelConfigToUse)
    }, [whiteLabelConfig])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        // Learn more about service workers: https://bit.ly/CRA-PWA
        // serviceWorker.unregister()
        serviceWorker.register({
            onSuccess: () => {
                setInitialised(true)
                timeoutId = setTimeout(() => setInitialised(false), 5000)
            },
            onUpdate: (registration: ServiceWorkerRegistration) => {
                setRegistration(registration)
                setUpdated(true)
            }
        })

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    const updateServiceWorker = () => {
        const registrationWaiting = registration?.waiting

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: "SKIP_WAITING" })

            registrationWaiting.addEventListener("statechange", e => {
                const target: any = e.target
                if (target.state === "activated") {
                    window.location.reload()
                }
            })
        }
    }

    const Message = getMessage(whiteLabelConfig)

    return (
        <div>
            <div className="systemMessages">
                {initialised && <SystemMessage>{Message("offlineUse")}</SystemMessage>}
                {updated && (
                    <SystemMessage>
                        {Message("newVersionAvailable")} <button onClick={updateServiceWorker}>{Message("updateNow")}</button>
                    </SystemMessage>
                )}
            </div>
            <FeedbackBar />
            <BrowserRouter>
                <Main />
            </BrowserRouter>
        </div>
    )
}

export default App
