import React, { Fragment, ChangeEvent, useContext, useEffect, useState, FunctionComponent } from "react"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import { Site } from "../interfaces/Site"
import { InteractionMode } from "../interfaces/InteractionMode"
import LoaderButton from "./LoaderButton"
import { useHistory } from "react-router"
import routes from "../routes"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import { getMessage } from "../utils/Tools"

interface GetSitesRequest {
    userId: number
}

export interface GetSitesResponse {
    success: boolean
    sites: Site[]
}

interface SetSiteRequest {
    userId: number
    siteId: number
    orgId: number
}

export interface SetSiteResponse {
    success: boolean
    site: Site
    apiKey: string
    canUseDebugMode: boolean
}

const SiteSelector: FunctionComponent = () => {
    const { operationalModeConfig, onSetOperationalModeConfig, connectedUser, onLogout, whiteLabelConfig } = useContext(AuthContext)
    const { setFeedback } = useContext(AppContext)
    const [sites, setSites] = useState<Site[]>([])
    const [siteId, setSiteId] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [interactionMode, setInteractionMode] = useState<InteractionMode>(operationalModeConfig?.interactionMode || InteractionMode.TOUCH)
    const [pictureCountdown, setPictureCountdown] = useState<number>(operationalModeConfig?.pictureCountdown || 3)
    const [resetCountdown, setResetCountdown] = useState<number>(operationalModeConfig?.resetCountdown || 3)
    const history = useHistory()

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        if (!connectedUser) {
            console.error(Message("getSitesNoConnectUser"))
            return
        }

        setLoading(true)

        const getSitesRequest: GetSitesRequest = {
            userId: connectedUser.id
        }

        const getSites = async () => {
            try {
                const { success, sites }: GetSitesResponse = await executeRequest({
                    endpoint: endpoints.agent.GET_SITES,
                    withToken: true,
                    requestType: RequestType.POST,
                    params: getSitesRequest
                })
                if (success) {
                    setSites(sites)
                    if (operationalModeConfig?.site?.id) {
                        setSiteId(operationalModeConfig?.site?.id)
                    }
                } else {
                    throw new Error()
                }
            } catch (error) {
                setFeedback({
                    type: FeedbackType.ERROR,
                    message: error.message,
                    showLoginPromptOnInvalidCredentials: true
                })
            } finally {
                setLoading(false)
            }
        }
        getSites()
    }, []) // eslint-disable-line

    const setSite = (event: ChangeEvent<HTMLSelectElement>) => {
        const siteId: number = parseInt(event.target.value)
        setSiteId(siteId === -1 ? null : siteId)
    }

    const selectSite = async () => {
        if (!connectedUser || !siteId) {
            console.error(Message("setSiteNoConnectUser"))
            return
        }

        setLoading(true)

        const setSiteRequest: SetSiteRequest = {
            userId: connectedUser.id,
            siteId,
            orgId: connectedUser.tempOrgId
        }

        try {
            const { success, site, apiKey, canUseDebugMode }: SetSiteResponse = await executeRequest({
                endpoint: endpoints.agent.SELECT_SITE,
                withToken: true,
                requestType: RequestType.POST,
                params: setSiteRequest,
            })

            if (success) {
                const operationalModeConfig: OperationalModeConfig = {
                    site,
                    orgId: connectedUser.tempOrgId,
                    apiKey,
                    interactionMode,
                    pictureCountdown,
                    resetCountdown,
                    canUseDebugMode
                }

                onSetOperationalModeConfig(operationalModeConfig)
                onLogout()
                history.push(routes.CAPTURE_PHOTO)
            } else {
                throw new Error()
            }
        } catch (error) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message,
                showLoginPromptOnInvalidCredentials: true
            })
            setLoading(false)
        }
    }

    const isContactless: boolean = interactionMode === InteractionMode.CONTACTLESS

    const renderOptions = (numrows: number) => {
        return  [...Array(numrows).keys()].map((index) =>
            <option key={index+1} value={index+1}> {index+1} {Message("seconds")} </option>
        )
    }

    return (
        <div className="whiteBox siteSelector">
            <h2>{Message("chooseSite")}</h2>
            <p>{Message("goIntoWorkerAttendance")}</p>
            <p>{Message("appDescription")}</p>

            {sites.length === 0 && loading && (
                <div className="loadingSites">
                    <div className="loader"></div> {Message("fetchingSites")}
                </div>
            )}
            {sites.length === 0 && !loading && (
                <div className="loadingSites">{Message("noSitesFound")}</div>
            )}

            {sites.length > 0 && (
                <Fragment>
                    <div className="field siteSelectorDropdown">
                        <select name="site" onChange={setSite} value={siteId || operationalModeConfig?.site?.id + ""}>
                            <option value={-1}>{Message("pleaseSelect")}</option>
                            {sites.map((site: Site) => {
                                return (
                                    <option key={site.id} value={site.id}>
                                        {site.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="field radioGroup">
                        <label htmlFor="InteractionMode.TOUCH" className={interactionMode == InteractionMode.TOUCH ? "checked": ""}>
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.TOUCH)}
                                    type="radio"
                                    id="InteractionMode.TOUCH"
                                    name="interactionMode"
                                    checked={interactionMode == InteractionMode.TOUCH} />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("touchInterface")}</div>
                                <div className="radioDetails">{Message("touchInterfaceDetails")}</div>
                            </div>
                        </label>
                        <label htmlFor="InteractionMode.CONTACTLESS" className={interactionMode == InteractionMode.CONTACTLESS ? "checked": ""}>
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.CONTACTLESS)}
                                    type="radio"
                                    id="InteractionMode.CONTACTLESS"
                                    name="interactionMode"
                                    checked={interactionMode == InteractionMode.CONTACTLESS} />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("contactlessInterface")}</div>
                                <div className="radioDetails">{Message("contactlessInterfaceDetails")}</div>
                                {isContactless && (
                                    <div className="radioOptions">
                                        <div className="radioOption">
                                            <div className="radioOptionText">{Message("pictureCountdown")}</div>
                                            <div className="radioOptionValue">
                                                <select
                                                    name="pictureCountdown"
                                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                                        setPictureCountdown( parseInt(event.target.value) )
                                                    }
                                                    value={pictureCountdown}>
                                                    {renderOptions(20)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="radioOption">
                                            <div className="radioOptionText">{Message("resetCountdown")}</div>
                                            <div className="radioOptionValue">
                                                <select
                                                    name="resetCountdown"
                                                    onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                                        setResetCountdown( parseInt(event.target.value) )
                                                    }
                                                    value={resetCountdown}>
                                                    {renderOptions(20)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>
                        <label htmlFor="InteractionMode.QR" className={interactionMode == InteractionMode.QR ? "checked": ""}>
                            <div className="radioHolder">
                                <input
                                    onChange={() => setInteractionMode(InteractionMode.QR)}
                                    type="radio"
                                    id="InteractionMode.QR"
                                    name="interactionMode"
                                    checked={interactionMode == InteractionMode.QR} />
                            </div>
                            <div className="radioTextHolder">
                                <div className="radioTitle">{Message("qrInterface")}</div>
                                <div className="radioDetails">{Message("qrInterfaceDetails")}</div>
                            </div>
                        </label>
                    </div>

                    <div className="field textAlignRight">
                        <LoaderButton onClick={selectSite} disabled={!siteId} loading={loading}>
                            {loading ? Message("settingSite") : Message("set")}
                        </LoaderButton>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default SiteSelector
