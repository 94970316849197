import React, { ChangeEvent, useContext, useEffect, useState, FunctionComponent } from "react"
import { Link } from "react-router-dom"
import "../assets/app.css"
import { RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import LoaderButton from "./LoaderButton"
import routes from "../routes"
import { useHistory } from "react-router"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import Cookies from "../providers/CookieProvider"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { getMessage } from "../utils/Tools"

export interface FormData {
    email: string
    password: string
}

export interface LoginRequest {
    email: string
    password: string
    trySSOLogin: boolean
    ssoToken: string
    ssoCallback: string
    loginSSO: boolean
}

const DEFAULT_FORM_STATE = { email: "", password: "" }

const Login: FunctionComponent = () => {
    const { onLogin, onLogout, operationalModeConfig, onSetWhiteLabelConfig, whiteLabelConfig } = useContext(AuthContext)
    const { setFeedback, feedback, onNavigate } = useContext(AppContext)
    const history = useHistory()

    const [ssoToken, setSSOToken] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [trySSOLogin, setTrySSOLogin] = useState<boolean>(true)
    const [validSSOAuth, setValidSSOAuth] = useState<boolean>(false)
    const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_STATE)

    const Message = getMessage(whiteLabelConfig)

    useEffect(() => {
        if (Cookies.get("azureToken") !== undefined) {
            setSSOToken(Cookies.get("azureToken"))
            setFormData({ ...formData, email: Cookies.get("email") })
            Cookies.removeSSO("azureToken")
            Cookies.removeSSO("email")
            Cookies.removeSSO("ssoDomain")
        }
        if (Cookies.get("azureError") !== undefined) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: Cookies.get("azureError").replaceAll("_"," ")
            })
            setFormData(DEFAULT_FORM_STATE)
            setLoading(false)
            Cookies.removeSSO("azureError")
            Cookies.removeSSO("ssoDomain")
        }

        onLogout()
    }, [])

    useEffect(() => {
        if (ssoToken !== "") {
            login()
        }
    }, [ssoToken])

    useEffect(() => {
        history.listen(() => {
            onNavigate()
        })
    }, [history, onNavigate])

    const onFieldFocus = () => {
        if (feedback) {
            setFeedback(undefined)
        }
    }

    const disabledStatus = (): boolean => {
        if (trySSOLogin) {
            return formData.email === ""
        }
        else {
            return formData.email === "" || formData.password === ""
        }
    }

    const createAndSubmitSamlForm = (samlRequest: any) => {
        const form = document.createElement('form')
        form.action = samlRequest.identityProviderUrl
        form.method = 'POST'

        form.innerHTML = '<input type="hidden" name="SAMLRequest" value="' + samlRequest.encodedIdentityRequest + '" /><div style="text-align: center;"><p>' + Message("samlRequestRedirect", [samlRequest.identityProviderName]) + '</p><p>' + Message("samlRequestRedirect1") + '</p></div><div style="text-align: right;"><button type="submit">Log in</button></div>'
        const samlForm = document.getElementById("samlForm")
        samlForm?.appendChild(form)
        form.submit();
    }

    const login = async () => {
        setLoading(true)

        const loginRequest: LoginRequest = {
            email: formData.email,
            password: formData.password,
            trySSOLogin: trySSOLogin,
            ssoToken: ssoToken,
            ssoCallback: window.location.href,
            loginSSO: false
        }

        try {

            const headers: Headers = new Headers({
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json; charset=utf-8"
            })

            let request = {
                method: RequestType.POST,
                body: JSON.stringify(loginRequest),
                headers
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.auth.LOGIN}`, request)
            const result = await response.json()

            setLoading(false)

            if (result.success) {
                if (result.samlRequest) {
                    setValidSSOAuth(true)
                    createAndSubmitSamlForm(result.samlRequest)
                } else {
                    const user: ConnectedUser = {
                        id: result.user.id,
                        email: result.user.email,
                        tempOrgId: result.user.orgId,
                        tempWhitelabelContext: result.user.tempWhitelabelContext,
                        token: result.user.token
                    }
                    onLogin(user)
                    if (whiteLabelConfig?.context !== user.tempWhitelabelContext){
                        const tempWhiteLabelConfig:WhiteLabelConfig = {
                            context: user.tempWhitelabelContext,
                            lang: whiteLabelConfig!.lang
                        }
                        onSetWhiteLabelConfig(tempWhiteLabelConfig)
                    }
                    history.push(routes.SELECT_SITE)
                }
            } else {
                if (result.errors.key === "NO_SSO_AUTH") {
                    setTrySSOLogin(false)
                }
                else {
                    setFeedback({
                        type: FeedbackType.ERROR,
                        message: result.errors.message
                    })
                }
            }
        } catch (error) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
            setFormData(DEFAULT_FORM_STATE)
            setLoading(false)
        }
    }

    const goToForgotPassword = () => {
        window.open(process.env.REACT_APP_FORGOT_PASSWORD_URL, "_blank")
    }

    return (
        <div className="whiteBox loginForm">
            <div className="inner">
                <h2>{Message("welcome")}</h2>

                <div className="field">
                    <label htmlFor="email">{Message("email")}</label>
                    <input
                        type="email"
                        name="email"
                        aria-label="email"
                        autoComplete="disabled"
                        value={formData.email}
                        placeholder={Message("emailPlaceHolder")}
                        onFocus={onFieldFocus}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFormData({ ...formData, email: e.currentTarget.value })
                        }
                    />
                </div>
                { !trySSOLogin && (
                    <div className="field">
                        <label htmlFor="password">{Message("password")}</label>
                        <input
                            type="password"
                            name="password"
                            aria-label="password"
                            value={formData.password}
                            placeholder={Message("passwordPlaceHolder")}
                            onFocus={onFieldFocus}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFormData({ ...formData, password: e.currentTarget.value })
                            }
                        />
                    </div>
                )}

                { validSSOAuth && (
                    <div id ="samlForm"></div>
                )}

                { !trySSOLogin && !validSSOAuth && (
                    <div className="field forgotYourPassword textAlignRight">
                        <div className="forgotYourPasswordButton" onClick={goToForgotPassword}>
                            {Message("forgotPassword")}
                        </div>
                    </div>
                )}

                <div className="field textAlignRight">
                    {operationalModeConfig && (
                        <Link className="button" to={routes.CAPTURE_PHOTO}>
                            {Message("backWorkerAttendance")}
                        </Link>
                    )}
                    { !validSSOAuth && (
                    <LoaderButton onClick={login} disabled={disabledStatus()} loading={loading}>
                        {Message("login")}
                    </LoaderButton>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Login
