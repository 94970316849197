import { useEffect, useRef } from "react"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"

export const findMessage = (key: string | undefined, messagesParam: { [key: string]: string }, attr: string[]): string => {
    if (!key) {
        return ""
    }

    let result = messagesParam[key]

    if (result && attr && attr.length !== 0) {
        attr.forEach((value, index) => {
            result = result.replace("{param" + (index+1) + "}", value)
        })
    }

    return result
}

export const getMessage = (whitelabel: WhiteLabelConfig | null): any => {
    if (whitelabel) {
        return require("../messages/" + whitelabel?.lang + "/" + whitelabel?.context).default
    }
    else {
        return require("../messages/EN/defaultMessages").default
    }
}

export const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef<() => void | null>()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        function tick() {
            if (typeof savedCallback?.current !== 'undefined') {
                savedCallback.current()
            }
        }

        if (delay !== null) {
            let id = setInterval(tick, delay)
            return () => {
                clearInterval(id)
            }
        }
    }, [delay])
}
