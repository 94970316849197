import React, { Fragment, useContext, useEffect, useState, FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import routes from "../routes"
import Login from "./Login"
import Footer from "./Footer"
import CapturePhoto from "./CapturePhoto"
import SiteSelector from "./SiteSelector"
import ProtectedRoute, { ProtectedRouteType } from "./ProtectedRoute"
import SlidingMenu from "./SlidingMenu"
import Cookies from "../providers/CookieProvider"
import { getMessage } from "../utils/Tools"
import LoginWithSso from "./LoginWithSso"
import { RequestType } from "api/APIUtils"
import endpoints from "api/endpoints"
import ClientStorage from "../utils/ClientStorage"

const redirectIfRequired = (
    connectedUser: ConnectedUser | null,
    operationalModeConfig: OperationalModeConfig | null
) => {
    if (connectedUser) {
        return <Redirect to={routes.SELECT_SITE} />
    }

    if (operationalModeConfig && Cookies.get("azureToken") === undefined && Cookies.get("azureError") === undefined) {
        return <Redirect to={routes.CAPTURE_PHOTO} />
    }
}

const Main: FunctionComponent = () => {
    const { connectedUser, operationalModeConfig, whiteLabelConfig } = useContext(AuthContext)
    const [isSsoLoginEnabled, setIsSsoLoginEnabled] = useState<boolean | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        getSsoLoginEnabled()
    }, [])

    const getSsoLoginEnabled = async () => {
        setIsLoading(true)
        try {

            const headers: Headers = new Headers({
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json; charset=utf-8"
            })

            let request = {
                method: RequestType.GET,
                headers
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.featureSwitches.GET_ENABLE_SSO_LOGIN_PAGE}`, request)
            const result = await response.json()

            if (result.success) {
                setIsSsoLoginEnabled(result.isSsoLoginEnabled)
                ClientStorage.setItem('IsSsoLoginEnabled', result.isSsoLoginEnabled)
                setIsLoading(false)
            } else {
                setIsSsoLoginEnabled(false)
                ClientStorage.setItem('IsSsoLoginEnabled', false)
                setIsLoading(false)
            }
        } catch (error) {
            setIsSsoLoginEnabled(false)
            ClientStorage.setItem('IsSsoLoginEnabled', false)
            setIsLoading(false)
        }
    }

    const Message = getMessage(whiteLabelConfig)

    const outputLoginRoute = () =>
        isLoading ? (
            <div className="loader loader-xl" />
        ) : isSsoLoginEnabled ? (
            <LoginWithSso />
        ) : (
            <Login />
        )

    return (
        <Fragment>
            {redirectIfRequired(connectedUser, operationalModeConfig)}
            <nav className="topBar">
                <img src={whiteLabelConfig?.context + "/logo.png"} alt={Message("orgName")} />
                <SlidingMenu />
            </nav>
            <div className="pageContent">
                <Switch>
                    <Route path={routes.LOGIN}>
                        <Login />
                    </Route>
                    <Route path={routes.LOGIN_WITH_SSO}>
                        <LoginWithSso />
                    </Route>
                    <Route path={routes.SELECT_SITE}>
                        <ProtectedRoute type={ProtectedRouteType.AGENT}>
                            <SiteSelector />
                        </ProtectedRoute>
                    </Route>
                    <Route path={routes.CAPTURE_PHOTO}>
                        <ProtectedRoute type={ProtectedRouteType.WORKER}>
                            <CapturePhoto />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/">{outputLoginRoute()}</Route>
                </Switch>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Main
